<template>
	<!-- 底部弹窗 -->
	<div class="writeSign">
		<van-action-sheet v-model="show" title="家长签名" @cancel='show = false'>
			<div class="content">
				<div class="carBox">
					<div style="background-color: white;border: 1px dashed #ccc;border-radius: 10px;">
						<vue-esign ref="esign" :width='800' :height="400" :isCrop="isCrop" :lineWidth="lineWidth"
							:lineColor="lineColor" :bgColor.sync="bgColor" />
					</div>

				</div>
				<div class="btn">
					<van-button size="small" round icon="edit" @click="handleReset" style="border-radius: 10px;">清除
					</van-button>
					<van-button size="small" round
						style="margin: 0 10px;color:#ff0000;border-radius: 10px;padding: 6px 18px;"
						@click="show = false">退出签名</van-button>
					<van-button @click="handleGenerate" plain size="small" round
						style="color:#000000;background-color: #ffe500;border-radius: 10px;padding: 6px 18px;">使用签名
					</van-button>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>


<script>
	import {
		Button,
		Toast,
		Icon,
		ActionSheet,
	} from 'vant'

	export default {
		name: "WriteSign",
		data() {
			return {
				lineWidth: 6,
				lineColor: '#000000',
				bgColor: '',
				resultImg: '',
				isCrop: false,
				path: '',
				id: '',
				show:false,
			}
		},
		components: {
			[Button.name]: Button,
			[Toast.name]: Toast,
			[Icon.name]: Icon,
			[ActionSheet.name]: ActionSheet,
		},
		beforeRouteEnter(to, from, next) {
			to.meta.keepAlive = true
			next(0)
			next(vm => {
				// 通过 `vm` 访问组件实例,将值传入oldUrl
				console.log(from.path)
				vm.path = from.path
			})
		},
		mounted() {
			// 编辑重签,携带id
			if (this.$route.query.id) {
				this.id = this.$route.query.id
			}
		},
		methods: {
			handleReset() {
				this.$refs['esign'].reset() //清空画布
			},
			// 使用签名
			handleGenerate() {
				this.$refs['esign'].generate().then(res => {
					this.resultImg = res // 得到了签字生成的base64图片
					// 页面返回
					//this.$parent.viewData.Base64Img = res
					this.$parent.Base64Img = res
					this.show = false
				}).catch(err => { //  没有签名，点击生成图片时调用
					this.$toast({
						message: '未签名!',
						type: 'warning'
					})
					//alert(err) // 画布没有签字时会执行这里 'Not Signned'
				})
			}
		}
	}
</script>


<style lang="less" scoped>
	.writeSign {
		height: 100%;
		display: flex;
		flex-direction: column;
		.content {
			//transform: rotateZ(90deg);
			//position: absolute;
			//width: 100%;
			//top: 16%;
			padding: 10px;
			// margin-top: 20px;
			.carBox {
				border-radius: 10px;
				//flex-direction: column;
				height: 400;
				width: 800;
				//border: 1px dashed #ccc;
				background-color: white;
				padding: 6px;
			}

			.btn {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: 20px;
			}
		}
	}
</style>
